'use client';

import { YODL_ENS_DOMAIN } from '@frontend/common';
import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import { justanameClient } from '../clients/justanameClient';

const useAccountEns = () => {
  const { address, status } = useAccount();

  const fetchSubname = async () => {
    if (!address) return;

    const response = await justanameClient.subnames.getSubnamesByAddress({
      address: address,
      chainId: 1,
    });

    if (!response) {
      throw new Error('No response from Justaname');
    }

    if (!response?.subnames) {
      throw new Error('No address');
    }

    const subname = response?.subnames.find(({ ens }) => {
      return ens.endsWith(YODL_ENS_DOMAIN);
    });

    return subname;
  };

  return useQuery({
    queryKey: ['addressSubnames', address, status],
    queryFn: async () => await fetchSubname(),
    enabled: Boolean(address) && status === 'connected',
  });
};

export default useAccountEns;
