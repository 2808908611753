import { FC, ReactNode } from 'react';
import * as Ably from 'ably';
import { v4 } from 'uuid';
import { AblyProvider } from 'ably/react';
import { hasAblyApiKey } from '@frontend/common';
import { useAccount } from 'wagmi';

type SafeAblyProviderProps = {
  children: ReactNode;
};

// Safely wrap the AblyProvider so that we don't crash if the API key is not set.
const SafeAblyProvider: FC<SafeAblyProviderProps> = ({ children }) => {
  const { address } = useAccount();
  if (!hasAblyApiKey) {
    return <>{children}</>;
  }

  const client = new Ably.Realtime({
    key: process.env.NEXT_PUBLIC_ABLY_API_KEY,
    clientId: `${address}-${v4()}`,
  });

  return <AblyProvider client={client}>{children}</AblyProvider>;
};

export default SafeAblyProvider;
