import { Flex } from '@radix-ui/themes';
import Image from 'next/image';

export const AvatarList = ({
  uris,
  slice,
  size,
}: {
  uris: string[];
  slice?: number;
  size?: number;
}) => {
  const uniqueUris = slice
    ? [...new Set(uris)].slice(0, slice)
    : [...new Set(uris)];

  return (
    <Flex>
      {uniqueUris.map((uri, index) => (
        <Image
          key={uri}
          src={uri}
          alt="avatar"
          width={size || 24}
          height={size || 24}
          className={`rounded-full ${index > 0 ? '-ml-2.5' : ''}`}
          style={{ borderColor: 'var(--gray-a3)', borderWidth: 3 }}
        />
      ))}
    </Flex>
  );
};
