import { useEffect, useState } from 'react';

interface OneTimeDisplayProps {
  uniqueId: string;
  shouldDisplay: boolean;
}

const useOneTimeDisplay = ({
  uniqueId,
  shouldDisplay,
}: OneTimeDisplayProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const storageKey = `one-time-display-${uniqueId}`;

  useEffect(() => {
    const hasBeenDisplayed = localStorage.getItem(storageKey);

    if (!hasBeenDisplayed && shouldDisplay) {
      setIsVisible(true);
    }
  }, [uniqueId, shouldDisplay, storageKey]);

  const handleDismiss = () => {
    localStorage.setItem(storageKey, 'true');
    setIsVisible(false);
  };

  return { isVisible, handleDismiss };
};

export default useOneTimeDisplay;
