import { z } from 'zod';
import { isAddress } from 'ethers';

export const Address = z
  .string()
  .refine((value) => !value.endsWith('.eth'), {
    message: 'ENS domain not yet supported, coming soon.',
    path: [],
  })
  .refine((value) => /^0x[a-fA-F0-9]{40}$/.test(value), {
    message: 'Invalid address',
    path: [],
  });

export const SavedLink = z.array(
  z.object({
    alias: z.string(),
    identifier: z.string(),
    source: z.string(),
  }),
);
// Define the Zod schema for validation
export const ensUsernameSchema = z.object({
  username: z
    .string()
    .min(6, { message: 'Username must be at least 6 characters' })
    .regex(/^[a-zA-Z0-9_]+$/, {
      message: 'Username can only contain letters, numbers, and underscores',
    })
    .refine((value) => !isAddress(value), {
      message: 'Username cannot be an Ethereum address',
    }),
});
