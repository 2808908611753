'use client';

import '@frontend/utils/jsonSerialize';

import UserStatePopupProvider from '@frontend/app/shared/providers/UserStatePopupProvider';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { generateWagmiConfig } from '@frontend/client/rainbowkit';
import { DOMAIN, ORIGIN, YODL_ENS_DOMAIN } from '@frontend/common';
import { JustWeb3Provider, JustWeb3ProviderConfig } from '@justweb3/widget';
import { Flex } from '@radix-ui/themes';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode, useEffect, useMemo } from 'react';
import { Address } from 'viem';
import { mainnet } from 'viem/chains';
import {
  cookieToInitialState,
  useAccount,
  useConnect,
  WagmiProvider,
} from 'wagmi';
import SafeAblyProvider from './SafeAblyProvider';
import { uniqBy } from 'lodash';
import { usePathname } from 'next/navigation';
import { useMediaQuery } from '@frontend/utils/UseMediaQuery';

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
  if (
    !process.env.NEXT_PUBLIC_POSTHOG_KEY ||
    !process.env.NEXT_PUBLIC_POSTHOG_HOST
  ) {
    console.error('Posthog not configured');
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageleave: true, // Enable automatic pageleave capture
  });
}

const providerUrl =
  process.env.NEXT_PUBLIC_MAINNET_PROVIDER_URL ||
  mainnet.rpcUrls.default.http[0];

const queryClient = new QueryClient();

const justweb3Config: JustWeb3ProviderConfig = {
  config: {
    origin: ORIGIN,
    domain: DOMAIN,
    signInTtl: 120000,
  },
  openOnWalletConnect: false,
  allowedEns: 'all',
  logo: '',
  ensDomains: [
    {
      chainId: mainnet.id,
      ensDomain: YODL_ENS_DOMAIN,
    },
  ],
  networks: [
    {
      chainId: mainnet.id,
      providerUrl,
    },
  ],
};

type ProvidersProps = {
  children: ReactNode;
  initialCookies: string | null | undefined;
  // isDapp=true if interactive, e.g. if part of a dapp.
  // isDapp=false if not interactive content, e.g. landers, tx explorer
  isDapp: boolean;
};

export function Providers({
  children,
  initialCookies,
  isDapp,
}: ProvidersProps) {
  const playwright = useYodlStore((state) => state.playwright);
  const setPlaywright = useYodlStore((state) => state.setPlaywright);
  const rainbowTheme = darkTheme();

  useEffect(() => {
    // DO NOT use the useSearchParams hook
    // It causes Rainbowkit to disconnect from the user's wallter for unknown reasons
    // the following code breaks the Rainbowkit button
    // const urlParams = useSearchParams();

    const urlParams = new URLSearchParams(window.location.search);
    const playwrightParam = urlParams.get('playwright');

    // set refid in localstorage
    const refid = urlParams.get('refid');
    if (refid && refid != '') {
      // do not overwrite refids, first refid counts.
      if (!localStorage.getItem('refid')) {
        localStorage.setItem('refid', refid);
      }
    }

    if (playwrightParam) {
      setPlaywright(playwrightParam as Address);
    }
  }, [setPlaywright]);

  const wagmiConfig = useMemo(
    () =>
      generateWagmiConfig({
        playwright,
      }),
    [playwright],
  );

  // initialCookies see: https://wagmi.sh/react/guides/ssr
  const initialState = useMemo(
    () => cookieToInitialState(wagmiConfig, initialCookies),
    [wagmiConfig, initialCookies],
  );

  const childrenWrapped = isDapp ? (
    <SafeAblyProvider>
      <UserStatePopupProvider>{children}</UserStatePopupProvider>
    </SafeAblyProvider>
  ) : (
    children
  );

  const content = (
    <PostHogProvider client={posthog}>
      {childrenWrapped}
      <ProgressBar
        height="4px"
        options={{ showSpinner: false }}
        shallowRouting
      />
    </PostHogProvider>
  );

  return (
    <WagmiProvider
      config={wagmiConfig}
      reconnectOnMount={true}
      initialState={initialState}
    >
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={rainbowTheme}>
          <AutoConnectProvider />
          <JustWeb3Provider config={justweb3Config}>{content}</JustWeb3Provider>
        </RainbowKitProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

function AutoConnectProvider() {
  const { connect, connectors } = useConnect();

  const { isConnected } = useAccount();
  const pathname = usePathname();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const sessionStorageKey = 'hasTriedAutoConnect';

  useEffect(() => {
    const connectWallet = async (hasTriedAutoConnect: boolean) => {
      // Check for playwright param
      const params = new URLSearchParams(window.location.search);
      const playwrightAddress = params.get('playwright');

      if (playwrightAddress) {
        const mockConnector = connectors.find((c) => c.id === 'mock');
        if (mockConnector) {
          connect({ connector: mockConnector });
          return;
        }
      }

      if (hasTriedAutoConnect || !isMobile || !window.ethereum) return; // Do not retry auto-connect for injected wallets. Also, only connect if on mobile device and window.ethereum is present.

      // Filter for injected connectors and remove duplicates by "name" field (Metamask may inject metamask and io.metamask)
      const injectedConnectors = uniqBy(
        connectors.filter((c) => c.type === 'injected'),
        'name',
      );

      if (injectedConnectors.length === 1 || !isMobile) {
        // connect only if there's exactly one injected wallet
        connect({ connector: injectedConnectors[0] });
      } else if (injectedConnectors.length === 2) {
        const zerionConnecter = injectedConnectors.find(
          (c) => c.name === 'Zerion',
        );
        if (zerionConnecter) {
          // auto-connect Zerion
          connect({ connector: zerionConnecter });
        }
      }

      sessionStorage.setItem(sessionStorageKey, 'true');
    };

    const hasTriedAutoConnect =
      sessionStorage.getItem(sessionStorageKey) === 'true';

    // Only auto-connect on /go path
    const isGoPath = pathname.startsWith('/go');

    if (!isConnected && isGoPath) {
      connectWallet(hasTriedAutoConnect);
    }
  }, [connect, connectors, isConnected, pathname]);

  return null;
}
