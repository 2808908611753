const generateFunnyCryptoUsername = (): string => {
  const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];
  const randomSuffix = suffixes[Math.floor(Math.random() * suffixes.length)];
  const randomNumber = Math.floor(Math.random() * 99);

  return `${randomPrefix}${randomSuffix}${randomNumber}`;
};

export default generateFunnyCryptoUsername;

const prefixes = [
  'ETH',
  'Ether',
  'Gas',
  'Gwei',
  'Staker',
  'Node',
  'Validator',
  'Shard',
  'EIP',
  'Merge',
  'Layer2',
  'Smart',
  'DeFi',
  'Dapp',
  'Chainlink',
  'Meta',
  'Web3',
  'Solidity',
  'Beacon',
  'ZK',
  'Rollup',
  'CryptoKitty',
  'DAO',
  'Ledger',
  'Airdrop',
  'Token',
  'NFT',
  'Crypto',
  'Blockchain',
  'Lambo',
  'FOMO',
  'Hodl',
  'Stake',
  'Satoshi',
  'Shill',
  'Whale',
  'Moon',
  'Pump',
  'Bear',
  'Bull',
  'Rocket',
  'Ape',
  'MetaMask',
  'Hardfork',
  'Etherium',
  'Blockchainer',
  'Mempool',
  'Nodejs',
  'GasFee',
  'ValidatorX',
  'Fork',
  'Miner',
  'Hashrate',
  'L2Rollup',
  'Protocol',
  'Bridge',
  'Sidechain',
  'Staking',
  'Bounty',
  'RugPull',
  'CryptoVibes',
  'Coinbase',
  'P2P',
];

const suffixes = [
  'Guru',
  'Whale',
  'Master',
  'Staker',
  'Hodler',
  'Builder',
  'Visionary',
  'Sailor',
  'Hunter',
  'Coder',
  'Sage',
  'Validator',
  'Hacker',
  'Developer',
  'Believer',
  'Seeker',
  'Explorer',
  'Dreamer',
  'Pioneer',
  'Wizard',
  'Shark',
  'Maverick',
  'Moonshot',
  'MemeLord',
  'King',
  'Legend',
  'Champion',
  'Knight',
  'Titan',
  'Ninja',
  'Savant',
  'Phantom',
  'Ghost',
  'Oracle',
  'Architect',
  'Kingpin',
  'Ruler',
  'Overlord',
  'Mogul',
  'Degen',
  'Trader',
  'Nomad',
  'Jedi',
  'Zenith',
  'Titan',
  'Alchemist',
  'Enchanter',
  'Lord',
  'Captain',
  'CaptainX',
  'Warlord',
  'Genius',
  'Shaman',
  'Mastermind',
  'Ruler',
  'Sage',
  'Titanium',
  'Ranger',
  'Vanguard',
  'Vortex',
  'Hurricane',
  'ChampionX',
  'Legendary',
  'Zer0x',
  'MetaShark',
  'Elder',
  'Cryptomancer',
];
