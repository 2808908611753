import {
  getDefaultConfig,
  getDefaultWallets,
  Wallet,
  WalletDetailsParams,
} from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  ledgerWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { Address, Chain, http } from 'viem';
import { Config, cookieStorage, createConnector, createStorage } from 'wagmi';
import { createConfig, mock } from '@wagmi/core';
import { defaultPublicRpc } from '@frontend/utils/env.client';
import { SUPPORTED_CHAINS } from '@frontend/common';

type WagmiConfigParams = {
  supportedChains?: Chain[];
  playwright?: Address;
};

const walletConnectMeta = {
  appName: 'yodl',
  appDescription: 'yodl',
  // required by wallet connect. if it does not match, then some wallets
  // will show a nasty warning. You have to enable verify api on walletconnect cloud.
  appUrl: 'https://yodl.me',
  // TODO: Move elsewhere
  appIcon:
    'https://framerusercontent.com/images/AAmKW17l9jseK4hJPDy2uYxVEdU.png',
};

export const mockedAccounts: readonly [Address, ...Address[]] = [
  '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266', // anvil 1
  '0x70997970c51812dc3a010c7d01b50e0d17dc79c8', // anvil 2
  '0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC', // anvil 3
];

// export function mockWallet(mockConfig?: {
//   accounts?: readonly [Address, ...Address[]];
// }) {
export function mockWallet(mockAddress?: Address) {
  return (): Wallet => ({
    id: 'mock',
    name: 'MockWallet',
    iconBackground: '#fff',
    iconUrl: '',
    installed: true,
    createConnector: (walletDetails: WalletDetailsParams) => {
      return createConnector((wagmiConfig) => ({
        ...mock({
          accounts: mockAddress ? [mockAddress] : mockedAccounts, // sets account from plqywright query param or falls back to hardcoded array

          // @ts-ignore
        })(wagmiConfig),
        ...walletDetails,
      }));
    },
  });
}

export const generateWagmiConfig = ({
  playwright,
}: WagmiConfigParams): Config => {
  const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID || '';

  const createTransports = () => {
    return Object.fromEntries(
      SUPPORTED_CHAINS.map((chain) => [
        chain.id,
        http(defaultPublicRpc(chain.id)),
      ]),
    );
  };

  const transports = createTransports();
  const { wallets } = getDefaultWallets();

  const myWallets = [
    ...wallets.map((wallet) => ({ ...wallet, shimDisconnect: true })),
    {
      groupName: 'Other',
      wallets: [argentWallet, trustWallet, ledgerWallet],
    },
  ];

  if (playwright) {
    myWallets.push({
      wallets: [mockWallet(playwright)],
      groupName: 'mock',
    });
  }

  const wagmiConfig = getDefaultConfig({
    chains: SUPPORTED_CHAINS,
    transports,
    multiInjectedProviderDiscovery: true,
    projectId,
    ...walletConnectMeta,
    ssr: true,
    storage: createStorage({
      storage: cookieStorage,
    }),
    wallets: myWallets,
  });

  return wagmiConfig;
};
